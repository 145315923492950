import "./Header.css";
import React from "react";
import { Link } from "react-router-dom";
const baselink = 'https://intelligaia.com'
function template() {
  return (
    <header className="header122020">
				<nav className="nav122020">
					<a className="nav-logo" href={baselink}>
						<img src="/logo-black.svg" alt="Intelligaia" />
					</a>
					<a className="nav-openresmenu">
						<span></span>
						<span></span>
						<span></span>
					</a>
					<ul className="nav122020-ul">
						<li className="nav122020-li1" data-active="services">
							<a href={baselink+'/services'}>CX Services</a>
						</li>

						<li className="nav122020-li1" data-active="work">
							<a href={baselink+'/work/'}>Work</a>
						</li>
            <li className="nav122020-li1 active" data-active="work">
							<Link to="/worksheet/$2a$10$ECS917h0r0gNIKgsPKyxaueUhTlE3gQZ6Lw3yA6iB41EqEaRxGAim">Worksheet</Link>
						</li>
						<li className="nav122020-li1" data-active="workshops">
							<a href="/">Design Sprint</a>
							<ul>
								<li className="nav122020-li2">
									<a href={baselink+'/workshops'} title="MVP in a Week Workshop" id="ds">
										MVP in a Week Workshop
									</a>
								</li>
								<li className="nav122020-li2">
									<a
										href={baselink+'/how-we-can-help'}
										title="How we can Help/FAQs"
										id="hwch"
									>
										How we can Help/FAQs
									</a>
								</li>
							</ul>
						</li>

						<li className="nav122020-li1" data-active="blogs">
							<a
								data-activate="blogs"
								className="mainemenuwithsubmenu"
								href="/"
							>
								Explore Insights
							</a>
							<ul>
								<li className="nav122020-li2" data-active="blogs">
									<a
										href={baselink+'/blog'}
										title="Articles, Journals, Toolkits"
										id="blogs"
									>
										Articles, Journals, Toolkits
									</a>
								</li>
								<li className="nav122020-li2" data-active="blogs">
									<a
										href={baselink+'/ebooks'}
										title="Thought Leadership (eBooks)"
										id="ebooks"
										className="active"
									>
										Thought Leadership (eBooks)
									</a>
								</li>
								<li className="nav122020-li2" data-active="blogs">
									<a
										href={baselink+'/customer-success-stories'}
										title="Customer Success Stories"
										id="xyz"
									>
										Customer Success Stories
									</a>
								</li>
							</ul>
						</li>
						<li className="nav-item getstartbtn">
							<a href={baselink+'/contact'} className="get-started-li-btn">
								Get Started
							</a>
						</li>
					</ul>
				</nav>
			</header>
  );
};

export default template;
