import React, { useEffect, useState } from "react";
import "./Mainsec.css";
import axios from "axios";
import autosize from "autosize";
// import MyDropzone from "../Fileupload";
import { Form, Button, ProgressBar, Alert } from "react-bootstrap";
import axiosInstance from "../Fileupload/axiosinstance";
// import SimpleReactFileUpload from '../fileuploader';
// import CustomUploader from "../Fileupload/customprogress";
// import Getuserdata from '../Getuserdata'
var pageurl = window.location.href;
var pageID = pageurl.split("worksheet/")[1];

let fileslist,
	files = [];
// var pageID = parts[parts.length - 1];
function Homepage() {
	const [currentStep, setcurrentStep] = useState(1);
	const [step1step, setstep1step] = useState("");
	const [step1ques, setstep1ques] = useState("");
	const [step1outcome, setstep1outcome] = useState("");
	const [step2step, setstep2step] = useState("");
	const [step2ques, setstep2ques] = useState("");
	const [step2outcome, setstep2outcome] = useState("");
	const [step3step, setstep3step] = useState("");
	const [step3ques, setstep3ques] = useState("");
	const [step3outcome, setstep3outcome] = useState("");
	const [step4step, setstep4step] = useState("");
	const [step4ques, setstep4ques] = useState("");
	const [step4outcome, setstep4outcome] = useState("");
	const [fileuploaded, setfileuploaded] = useState("");
	const [name, setname] = useState("");
	const [oldfiles, setoldfiles] = useState("");
	const [feed_link, setfeed_link] = useState(pageID);
	const [formsubmitted, setformsubmitted] = useState(false);

	//File Uploading component
	const [progress, setProgress] = useState(); //to set the progressbar
	const [ThisProgressbar, setThisProgressbar] = useState(0); // progressbar index
	const [error, setError] = useState();
	const [ActiveButton, setActiveButton] = useState(true);
	const [uploaded, setUploaded] = useState(false);
	const [currentfile, setcurrentfile] = useState(0);
	const [filesArray, changeFilesarray] = useState([]);
	const [filesSelected, setfilesSelected] = useState(false);
	const [alluploaded, setalluploaded] = useState(false);
	var responsesubmitted= false;
	var tempfilearray,
		uploadStatus = [];
	const onChange = (e) => {
		setalluploaded(true)
		changeFilesarray([]);
		tempfilearray = [];
		const ifilesV = document.getElementById("uploadfile");
		// var imageContainer = [];
		let ifiles = ifilesV.files;
		if (ifiles.length > 0) {
			for (let i = 0; i <= ifilesV.files.length - 1; i++) {
				const fsize = ifiles.item(i).size;
				// console.log(fsize);
				const file = Math.round(fsize / 1024);
				// console.log(file);
				// The size of the file.
				if (file > 102400) {
					return false;
				}
			}
		}
		tempfilearray = [...filesArray];
		if (ifiles.length) {
			setActiveButton(false);
		}
		Array.from(ifiles).forEach((ifile, index) => {
			tempfilearray.push(ifile);
			uploadStatus.push({ upload: false });
		});
		changeFilesarray(tempfilearray);
		setUploaded(uploadStatus);
	};

	const handleRemoveItem = (index) => {
		console.log("Index", index);
		var tempFiles = [...filesArray];
		console.log("before" + tempFiles);
		if (tempFiles.length > 1) {
			tempFiles.splice(index, 1);
			changeFilesarray(tempFiles);
		} else {
			changeFilesarray([]);
			setActiveButton(true);
			document.getElementById("uploadfile").value = null;
		}
	};
	const UploadsubmitHandler = () => {
		// e.preventDefault(); //prevent the form from submitting
		let formData = new FormData();
		var imageContainer = [...filesArray];
		var imagesleft = [...filesArray];
		formData.append("feed_link", pageID);
		setError("");		
		var check = true;
		function uploadAxios() {
			if (imageContainer.length >= 1 && check) {
				let percentCompleted = 0;
				var selectFile = imagesleft.length - imageContainer.length;
				// console.log(selectFile);
				formData.append("file", imagesleft[selectFile]);
				check = false;
				setActiveButton(true);
				axiosInstance
					.post("", formData, {
						headers: {
							"Content-Type": "multipart/form-data",
						},
						onUploadProgress: (data) => {
							setProgress(Math.round((100 * data.loaded) / data.total));
							setThisProgressbar(selectFile);
							percentCompleted = Math.round((100 * data.loaded) / data.total);
						},
					})
					.then((response) => {
						imageContainer.pop();
						check = true;
						formData.delete("file");
						uploadAxios();
						if (percentCompleted > 99) {
							uploadStatus[selectFile] = true;
							setUploaded(uploadStatus);
						}
						if(imageContainer.length === 0){
							setalluploaded(false);
						}
						if(imageContainer.length === 0 && responsesubmitted === true){
							var redirectTo = "/thankyou/" + pageID;
							window.location = redirectTo;
						}
					});
			} else {
				return;
			}
		}
		uploadAxios();
	};

	//File Uploading component ends here

	const handleChange = (event) => {
		const { name, value } = event.target;
		switch(name) {
			case 'step1step':
				setstep1step(value);
			  break;
			  case 'step1ques':
				setstep1ques(value);
			  break;
			  case 'step1outcome':
				setstep1outcome(value);
			  break;
			  case 'step2step':
				setstep2step(value);
			  break;
			  case 'step2ques':
				setstep2ques(value);
			  break;
			  case 'step2outcome':
				setstep2outcome(value);
			  break;
			  case 'step3step':
				setstep3step(value);
			  break;
			  case 'step3ques':
				setstep3ques(value);
			  break;
			  case 'step3outcome':
				setstep3outcome(value);
			  break;
			  case 'step4step':
				setstep4step(value);
			  break;
			  case 'step4ques':
				setstep4ques(value);
			  break;
			  case 'step4outcome':
				setstep4outcome(value);
			  break;
			  
			default:
			  return ;
		  }
		autosize(document.querySelectorAll("textarea"));
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		axios
			.post("https://sendgrid.intelligaia.com/email/putUserFeedback", {
				feed_link: feed_link,
				feedback: [
					{ step: step1step, question: step1ques, outcome: step1outcome },
					{ step: step2step, question: step2ques, outcome: step2outcome },
					{ step: step3step, question: step3ques, outcome: step3outcome },
					{ step: step4step, question: step4ques, outcome: step4outcome },
				],
				worksheet: "worksheet1",
			})
			.then((res) => {
				console.log(res);
				responsesubmitted = true;
				console.log(res.data+'___'+responsesubmitted);
				if(alluploaded === true){
					UploadsubmitHandler();
				}
				else{
					var redirectTo = "/thankyou/" + pageID;
					window.location = redirectTo;
				}
		
			});
	};

	const _next = () => {
		var currentSteps = currentStep;
		console.log(currentSteps);
		currentSteps = currentStep >= 3 ? 4 : currentStep + 1;
		setcurrentStep(currentSteps);
		window.scrollTo(0, 330)
	};

	const _prev = () => {
		var currentSteps = currentStep;
		currentSteps = currentStep <= 1 ? 1 : currentStep - 1;
		setcurrentStep(currentSteps);
		window.scrollTo(0, 330)
	};

	/*
	 * the functions for our button
	 */
	const previousButton = () => {
		var currentSteps = currentStep;
		if (currentSteps !== 1) {
			return (
				<button
					className="btn btn-secondary"
					type="button"
					onClick={() => _prev()}
				>
					Previous
				</button>
			);
		}
		return null;
	};

	const nextButton = () => {
		var currentSteps = currentStep;
		if (currentSteps < 4) {
			return (
				<button
					className="btn btn-primary float-right"
					type="button"
					onClick={() => _next()}
				>
					Next
				</button>
			);
		}
		return null;
	};
	const deleteFile = (filename, index) => {
		let deleteThisFile = filename;
		var r = window.confirm("Please confirm you want to delete " + filename);
		if (r == true) {
			axios
				.post("https://sendgrid.intelligaia.com/email/delete_file", {
					file_name: deleteThisFile,
					feed_link: pageID,
				})
				.then((res) => {
					files = oldfiles;
					files.splice(index, 1);
					fileslist = [...files];
					setoldfiles([...files]);
				});
		} else {
			return false;
		}
	};



	useEffect(() => {
		axios
			.post("https://sendgrid.intelligaia.com/email/getUserFeedback", {
				feed_link: pageID,
			})
			.then((response) => {
				setstep1step(response.data.data[0].feedback[0].step);
				setstep1ques(response.data.data[0].feedback[0].question);
				setstep1outcome(response.data.data[0].feedback[0].outcome);
				setstep2step(response.data.data[0].feedback[1].step);
				setstep2ques(response.data.data[0].feedback[1].question);
				setstep2outcome(response.data.data[0].feedback[1].outcome);
				setstep3step(response.data.data[0].feedback[2].step);
				setstep3ques(response.data.data[0].feedback[2].question);
				setstep3outcome(response.data.data[0].feedback[2].outcome);
				setstep4step(response.data.data[0].feedback[3].step);
				setstep4ques(response.data.data[0].feedback[3].question);
				setstep4outcome(response.data.data[0].feedback[3].outcome);
				setoldfiles(response.data.data[0].files);
			})
			.catch(function (error) {
				console.log(error);
			});
	}, [feed_link]);


	//Show uploaded files
	const Filessec = () => {
		files = oldfiles;
		fileslist = [...files];
		return (
			<>
				{fileslist.map((file, index) => {
					var filename = file.file_name;
					if (
						filename.endsWith("png") ||
						filename.endsWith("jpg") ||
						filename.endsWith("jpeg") ||
						filename.endsWith("svg")
					) {
						return (
							<li key={index}>
								<img src={file.file_link} className="img-fluid" />
								<button
									onClick={() => deleteFile(file.file_name, index)}
									title="Delete File"
								>
									&#10005;
								</button>
							</li>
						);
					} else if (
						filename.endsWith("webm") ||
						filename.endsWith("mp4") ||
						filename.endsWith("mkv") ||
						filename.endsWith("wmv") ||
						filename.endsWith("ogv") ||
						filename.endsWith("ogg")
					) {
						return (
							<li key={index}>
								<a href={file.file_link} target="_blank">
									<img src="/mp4.svg"></img>
								</a>
								<button
									onClick={() => deleteFile(file.file_name, index)}
									title="Delete File"
								>
									&#10005;
								</button>
							</li>
						);
					} else if (
						filename.endsWith("doc") ||
						filename.endsWith("docx") ||
						filename.endsWith("txt")
					) {
						return (
							<li key={index}>
								<a href={file.file_link} target="_blank">
									<img src="/doc.svg"></img>
								</a>
								<button
									onClick={() => deleteFile(file.file_name, index)}
									title="Delete File"
								>
									&#10005;
								</button>
							</li>
						);
					} else if (filename.endsWith("pdf")) {
						return (
							<li key={index}>
								<a href={file.file_link} target="_blank">
									<img src="/pdf.svg"></img>
								</a>
								<button
									onClick={() => deleteFile(file.file_name, index)}
									title="Delete File"
								>
									&#10005;
								</button>
							</li>
						);
					} else if (
						filename.endsWith("rar") ||
						filename.endsWith("tar") ||
						filename.endsWith("zip")
					) {
						return (
							<li key={index}>
								<a href={file.file_link} target="_blank">
									<img src="/zip.svg"></img>
								</a>
								<button
									onClick={() => deleteFile(file.file_name, index)}
									title="Delete File"
								>
									&#10005;
								</button>
							</li>
						);
					}
				})}
			</>
		);
	};

	return (
		<React.Fragment>
			<div className="mainsec mb-5 pb-5">
				<div className="Heading-sec">
					<div className="container">
						<div className="row">
							<div className="col">
								<h1>Sprint Preparation Worksheet</h1>
								<p>
									How to get started to make the sprint smoother, more
									<br />
									enjoyable and just overall better.
								</p>
							</div>
							<div className="col relate-ebook">
								<img src="/mvp-relate.svg"></img>
							</div>
						</div>
						<div className="row">
							<div className="col bookmark">
								<span className="bookmark-icon"></span> To Bookmark this page
								press <kbd>ctrl</kbd> + <kbd>d</kbd> or <kbd>cmd</kbd> +{" "}
								<kbd>d</kbd>
							</div>
						</div>
					</div>
				</div>
				<main className="tips-include">
					<div className="container">
						<div className="tips-sec">
							<div className="tips d-flex">
								<div className="tip-img">
									<img src="/tips.svg" />
								</div>
								<div className="tips-copy">
									<h5>Pro Tip</h5>
									<p>
										It is to get things started but will change once we get new
										inputs from the whole group
									</p>
								</div>
							</div>
						</div>
						<form onSubmit={handleSubmit} className="my-5">
							<Step1
								icurrentStep={currentStep}
								handleChange={handleChange}
								feed_link={feed_link}
								step1step={step1step}
								step1ques={step1ques}
								step1outcome={step1outcome}
							/>
							<Step2
								icurrentStep={currentStep}
								handleChange={handleChange}
								step2step={step2step}
								step2ques={step2ques}
								step2outcome={step2outcome}
							/>
							<Step3
								icurrentStep={currentStep}
								handleChange={handleChange}
								step3step={step3step}
								step3ques={step3ques}
								step3outcome={step3outcome}
							/>
							<Step4
								icurrentStep={currentStep}
								handleChange={handleChange}
								step4step={step4step}
								step4ques={step4ques}
								step4outcome={step4outcome}
							/>
							{previousButton()}
							{nextButton()}
						</form>
						<div className="files-listing">
							<ul>{Filessec()}</ul>
						</div>
						<div className="uploadsec-main">
							<Form
								method="post"
								encType="multipart/form-data"
							>
								<div className="uploadsec">
									<input
										type="file"
										title=""
										className=""
										id="uploadfile"
										label=""
										name="file"
										accept="image/*,.pdf,video/*,.doc,.docx,.txt,.zip,.rar,.tar,.mov"
										multiple
										onChange={(e) => {
											onChange(e.target.files);
										}}
									/>
									<div className="dropzone-area text-muted full-width">
										<img src="/upload-icon.svg" />
										<p>
											Drag and drop file here or{" "}
											<span className="text-orange">browse</span> to upload
										</p>
									</div>
									<div className="filereviews  full-width" id="filereviews">
										{filesArray.map((file, index) => {
											// console.log(progress);
											return (
												<div
													className="alert alert-secondary alert-dismissible fade show"
													id={index}
													data-id={index}
													key={index}
												>
													<div
														className={`row ${
															uploaded[index] === true ? "uploaded" : ""
														}`}
													>
														<div className="col-12">
															<span>
																<i>
																	<img src="/file-icon.svg" alt="File" />
																</i>
																{file.name}
															</span>
															<div className="col-12">
																{!error &&
																progress &&
																progress < 100 &&
																ThisProgressbar === index ? (
																	<ProgressBar
																		now={progress}
																		label={`${progress}%`}
																	/>
																) : (
																	<></>
																)}
																{uploaded[index] === true ? (
																	<span className="success bg-green">
																		<img src="/uploaded.svg" />
																	</span>
																) : (
																	<></>
																)}
															</div>
														</div>
													</div>
													<button
														type="button"
														className="close"
														data-dismiss="alert"
														aria-label="Close"
														name={file.name}
														onClick={() => handleRemoveItem(index)}
													>
														&times;
													</button>
												</div>
											);
										})}{" "}
										{/* <img id="preview"></img> */}
									</div>
									<div className=" full-width">
										<small className="text-muted">
											Only Images, Videos, Docs, txt, PDF and Compressed files
										</small>
										<div className="col-12 mt-3">
											{/* <button
												variant="info"
												type="submit"
												disabled={ActiveButton}
												className="btn btn-primary"
											>
												Upload
											</button> */}
											<a className={`btn btn-primary ${
															ActiveButton === true ? "disabled" : ""
														}`} href="javascript:void(0);" onClick={()=>UploadsubmitHandler()}>Upload</a>
										</div>
									</div>
								</div>
								{error && <Alert variant="danger">{error}</Alert>}
							</Form>
						</div>
					</div>
				</main>
			</div>
		</React.Fragment>
	);
}

function Step1(props) {
	if (props.icurrentStep !== 1) {
		return null;
	}
	return (
		<div className="worksheet-multistep" id="step_1">
			<div className="purpose-sec mt-5">
				<h3>Purpose</h3>
				<ul className="ml-3 px-0">
					<li className="mb-3">
						Collect some background information and get an idea about what the
						team will be thinking about and doing in the workshop.
					</li>
					<li className="mb-3">
						Having a small catch up chat is an amazing way to get a great
						headstart and getting an understanding about the topic.
					</li>
				</ul>
			</div>
			<div className="steps-sec">
				<h4 className="text-success mt-5">Steps</h4>
				<div className="mb-3">
					<input
						type="hidden"
						name="feed_link"
						value={props.feed_link}
						onChange={props.handleChange}
					></input>
					<label htmlFor="exampleFormControlTextarea1" className="form-label">
						Pre Sprint Conversation with Product Managers/Product Owners
					</label>
					<textarea
						placeholder="Write your answer here"
						className="form-control"
						id="exampleFormControlTextarea1"
						name="step1step"
						rows="3"
						value={props.step1step}
						onChange={props.handleChange}
					></textarea>
				</div>
			</div>
			<div className="ques-sec">
				<h4 className="text-success mt-5">Questions</h4>
				<div className="mb-3">
					<label htmlFor="exampleFormControlTextarea1" className="form-label">
						What are you trying to achieve?
					</label>
					<textarea
						placeholder="Write your answer here"
						className="form-control"
						id="exampleFormControlTextarea1"
						name="step1ques"
						rows="3"
						value={props.step1ques}
						onChange={props.handleChange}
					></textarea>
				</div>
			</div>
			<div className="Outcome-sec">
				<h4 className="text-success mt-5">Outcome</h4>
				<div className="mb-3">
					<label htmlFor="exampleFormControlTextarea1" className="form-label">
						Helps formulate How Might We (HMW’s) questions
					</label>
					<textarea
						placeholder="Write your answer here"
						className="form-control"
						id="exampleFormControlTextarea1"
						name="step1outcome"
						rows="3"
						value={props.step1outcome}
						onChange={props.handleChange}
					></textarea>
				</div>
			</div>
		</div>
	);
}

function Step2(props) {
	if (props.icurrentStep !== 2) {
		return null;
	}
	return (
		<div className="worksheet-multistep" id="step_2">
			<div className="purpose-sec mt-5">
				<h3>Purpose</h3>
				<ul className="ml-3 px-0">
					<li className="mb-3">
						Framing our own version of what we think a Long Term Goal should be
						and eventually agreeing on the main goal for the sprint.
					</li>
				</ul>
			</div>
			<div className="steps-sec">
				<h4 className="text-success mt-5">Steps</h4>
				<div className="mb-3">
					<label htmlFor="exampleFormControlTextarea1" className="form-label">
						Talk about Long Term Goal
					</label>
					<textarea
						placeholder="Write your answer here"
						className="form-control"
						id="exampleFormControlTextarea1"
						name="step2step"
						rows="3"
						value={props.step2step}
						onChange={props.handleChange}
					></textarea>
				</div>
			</div>
			<div className="ques-sec">
				<h4 className="text-success mt-5">Questions</h4>
				<div className="mb-3">
					<label htmlFor="exampleFormControlTextarea1" className="form-label">
						What are the top 3 questions you would like to have answered in the
						Sprint?
						<br />
						Where do we want to be in six months, a year, or even five years
						from now?
					</label>
					<textarea
						placeholder="Write your answer here"
						className="form-control"
						id="exampleFormControlTextarea1"
						name="step2ques"
						rows="3"
						value={props.step2ques}
						onChange={props.handleChange}
					></textarea>
				</div>
			</div>
			<div className="Outcome-sec">
				<h4 className="text-success mt-5">Outcome</h4>
				<div className="mb-3">
					<label htmlFor="exampleFormControlTextarea1" className="form-label">
						Identify and Define Business Objectives and Expected Experience
						Outcomes
					</label>
					<textarea
						placeholder="Write your answer here"
						className="form-control"
						id="exampleFormControlTextarea1"
						name="step2outcome"
						rows="3"
						value={props.step2outcome}
						onChange={props.handleChange}
					></textarea>
				</div>
			</div>
		</div>
	);
}

function Step3(props) {
	if (props.icurrentStep !== 3) {
		return null;
	}
	return (
		<React.Fragment>
			<div className="worksheet-multistep" id="step_3">
				<div className="purpose-sec mt-5">
					<h3>Purpose</h3>
					<ul className="ml-3 px-0">
						<li className="mb-3">
							Think about what are the biggest challenges in trying to reach the
							long time goal and maybe even turn them into solution oriented
							questions.
						</li>
					</ul>
				</div>
				<div className="steps-sec">
					<h4 className="text-success mt-5">Steps</h4>
					<div className="mb-3">
						<label htmlFor="exampleFormControlTextarea1" className="form-label">
							List down the main challenges and situations
						</label>
						<textarea
							placeholder="Write your answer here"
							className="form-control"
							id="exampleFormControlTextarea1"
							name="step3step"
							rows="3"
							value={props.step3step}
							onChange={props.handleChange}
						></textarea>
					</div>
				</div>
				<div className="ques-sec">
					<h4 className="text-success mt-5">Questions</h4>
					<div className="mb-3">
						<label htmlFor="exampleFormControlTextarea1" className="form-label">
							What is the product/ service/business? What’s the challenge?
							<br />
							What would you like to know from the end user?
						</label>
						<textarea
							placeholder="Write your answer here"
							className="form-control"
							id="exampleFormControlTextarea1"
							name="step3ques"
							rows="3"
							value={props.step3ques}
							onChange={props.handleChange}
						></textarea>
					</div>
				</div>
				<div className="Outcome-sec">
					<h4 className="text-success mt-5">Outcome</h4>
					<div className="mb-3">
						<label htmlFor="exampleFormControlTextarea1" className="form-label">
							Helps formulate Can We questions
						</label>
						<textarea
							placeholder="Write your answer here"
							className="form-control"
							id="exampleFormControlTextarea1"
							name="step3outcome"
							rows="3"
							value={props.step3outcome}
							onChange={props.handleChange}
						></textarea>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
}
function Step4(props) {
	if (props.icurrentStep !== 4) {
		return null;
	}
	return (
		<React.Fragment>
			<div className="worksheet-multistep" id="step_4">
				<div className="purpose-sec mt-5">
					<h3>Purpose</h3>
					<ul className="ml-3 px-0">
						<li className="mb-3">
							It’s crucial to think about the customers and the target market
							you may have or already know. This will help choose a behavior
							that we want to influence through the solution.
						</li>
					</ul>
				</div>
				<div className="steps-sec">
					<h4 className="text-success mt-5">Steps</h4>
					<div className="mb-3">
						<label htmlFor="exampleFormControlTextarea1" className="form-label">
							Ask general questions about customers and the target markets
						</label>
						<textarea
							placeholder="Write your answer here"
							className="form-control"
							id="exampleFormControlTextarea1"
							name="step4step"
							rows="3"
							value={props.step4step}
							onChange={props.handleChange}
						></textarea>
					</div>
				</div>
				<div className="ques-sec">
					<h4 className="text-success mt-5">Questions</h4>
					<div className="mb-3">
						<label htmlFor="exampleFormControlTextarea1" className="form-label">
							Who are the existing and target customers for the sprint? What’s
							the gap between them? What does the typical user journey look
							like? What’s working well, what’s not?
						</label>
						<textarea
							placeholder="Write your answer here"
							className="form-control"
							id="exampleFormControlTextarea1"
							name="step4ques"
							rows="3"
							value={props.step4ques}
							onChange={props.handleChange}
						></textarea>
					</div>
				</div>
				<div className="Outcome-sec">
					<h4 className="text-success mt-5">Outcome</h4>
					<div className="mb-3">
						<label htmlFor="exampleFormControlTextarea1" className="form-label">
							Leads to Target profile and to initiate a first draft of the Map
						</label>
						<textarea
							placeholder="Write your answer here"
							className="form-control"
							id="exampleFormControlTextarea1"
							name="step4outcome"
							rows="3"
							value={props.step4outcome}
							onChange={props.handleChange}
						></textarea>
					</div>
				</div>
			</div>
			<button className="btn btn-success float-right" type="submit">
				Submit
			</button>
		</React.Fragment>
	);
}
export default Homepage;
