import logo from "./logo.svg";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import Header from "./Header";
import Footer from './Footer';
// import Mainsec from './Mainsec';
import Homepage from './Mainsec/homepage';
import Donwloadresponses from './Donwloadresponses';
import { Switch, Route } from "react-router-dom";
function App(props) {
	return (
		<div className="App">
			<Header></Header>
			<Switch>
				<Route path="/worksheet/:id" render={(props) => <Homepage {...props} />} />
				<Route path="/thankyou/:id" render={(props) => <Donwloadresponses {...props} />} />
			</Switch>
			<Footer></Footer>
		</div>
	);
}

export default App;
