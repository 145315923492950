import "./Footer.css";
import React from "react";

function template() {
  return (
    <div className="footer-container">
				<div className="container">
					<div className="row">
						<div className="social-icon-cont">
							<p className="success-roadmap">
								<a href="/contact">Get to know us!</a>
							</p>

							<span className="links-container">
								<a
									href="https://twitter.com/intelligaia?lang=en"
									target="_blank"
									className="icon-twitter disable-share"
								>
									<svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="twitter" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className=""><path fill="#ffffff" d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z" className=""></path></svg>
								</a>
								<a
									href="https://www.instagram.com/intelligaia/"
									target="_blank"
									className="icon-insta"
								>
									<svg
										width="25"
										height="24"
										viewBox="0 0 25 24"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M16.9883 0H7.98828C3.84678 0 0.488281 3.3585 0.488281 7.5V16.5C0.488281 20.6415 3.84678 24 7.98828 24H16.9883C21.1298 24 24.4883 20.6415 24.4883 16.5V7.5C24.4883 3.3585 21.1298 0 16.9883 0ZM22.2383 16.5C22.2383 19.395 19.8833 21.75 16.9883 21.75H7.98828C5.09328 21.75 2.73828 19.395 2.73828 16.5V7.5C2.73828 4.605 5.09328 2.25 7.98828 2.25H16.9883C19.8833 2.25 22.2383 4.605 22.2383 7.5V16.5Z"
											fill="white"
										></path>
										<path
											d="M12.4883 6C9.17478 6 6.48828 8.6865 6.48828 12C6.48828 15.3135 9.17478 18 12.4883 18C15.8018 18 18.4883 15.3135 18.4883 12C18.4883 8.6865 15.8018 6 12.4883 6ZM12.4883 15.75C10.4213 15.75 8.73828 14.067 8.73828 12C8.73828 9.9315 10.4213 8.25 12.4883 8.25C14.5553 8.25 16.2383 9.9315 16.2383 12C16.2383 14.067 14.5553 15.75 12.4883 15.75Z"
											fill="white"
										></path>
										<path
											d="M18.9348 6.3501C19.3764 6.3501 19.7343 5.99215 19.7343 5.5506C19.7343 5.10905 19.3764 4.7511 18.9348 4.7511C18.4932 4.7511 18.1353 5.10905 18.1353 5.5506C18.1353 5.99215 18.4932 6.3501 18.9348 6.3501Z"
											fill="white"
										></path>
									</svg>
								</a>
								<a
									href="https://medium.com/@Intelligaia"
									target="_blank"
									className="icon-medium"
								>
									<svg
										width="30"
										height="24"
										viewBox="0 0 30 24"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<circle
											r="8.5"
											transform="matrix(1 0 0 -1 8.5 12.5)"
											fill="white"
										></circle>
										<ellipse
											rx="4"
											ry="7.5"
											transform="matrix(1 0 0 -1 22 12.5)"
											fill="white"
										></ellipse>
										<ellipse
											rx="1.5"
											ry="6.5"
											transform="matrix(1 0 0 -1 28.5 12.5)"
											fill="white"
										></ellipse>
									</svg>
								</a>
								<a
									href="https://www.linkedin.com/company/intelligaia/?viewAsMember=true"
									target="_blank"
									className="icon-linkedin disable-share"
								>
									<svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="linkedin-in" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="#ffffff" d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"></path></svg>
								</a>
								<a
									href="https://dribbble.com/intelligaia"
									target="_blank"
									className="icon-dribbble"
								>
									<svg
										width="32"
										height="25"
										viewBox="0 0 32 33"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<title id="linkdin">linkdin</title>
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M16 0.0332031C7.16704 0.0332031 0 7.20024 0 16.0332C0 24.8662 7.16704 32.0332 16 32.0332C24.8156 32.0332 32 24.8662 32 16.0332C32 7.20024 24.8156 0.0332031 16 0.0332031ZM26.5683 7.40847C28.4772 9.73384 29.6226 12.7013 29.6573 15.9117C29.2061 15.8249 24.6941 14.9052 20.1475 15.4779C20.0434 15.2523 19.9566 15.0093 19.8525 14.7664C19.5748 14.107 19.2625 13.4302 18.9501 12.7881C23.9827 10.7404 26.2733 7.79026 26.5683 7.40847ZM16 2.39329C19.4707 2.39329 22.6464 3.6948 25.0586 5.8293C24.8156 6.17637 22.7505 8.9356 17.8916 10.7577C15.6529 6.64491 13.1714 3.27832 12.7896 2.75771C13.8134 2.51476 14.8894 2.39329 16 2.39329ZM10.1866 3.67746C10.551 4.16335 12.9805 7.54732 15.2538 11.5733C8.86768 13.274 3.22778 13.2393 2.62039 13.2393C3.50541 9.00501 6.36877 5.48223 10.1866 3.67746ZM2.32538 16.0505C2.32538 15.9117 2.32538 15.7729 2.32538 15.6341C2.9154 15.6514 9.54448 15.7382 16.3644 13.6905C16.7636 14.454 17.128 15.2349 17.475 16.0159C17.3015 16.0679 17.1106 16.12 16.9371 16.172C9.89155 18.4454 6.14317 24.6579 5.83082 25.1785C3.6616 22.7664 2.32538 19.556 2.32538 16.0505ZM16 29.7078C12.8417 29.7078 9.92624 28.6319 7.61821 26.8271C7.86118 26.3239 10.6378 20.979 18.3427 18.2892C18.3774 18.2718 18.3948 18.2718 18.4295 18.2544C20.3557 23.2349 21.1367 27.4171 21.3449 28.6145C19.6963 29.326 17.8916 29.7078 16 29.7078ZM23.6182 27.3651C23.4794 26.5321 22.7506 22.5408 20.9631 17.6297C25.2495 16.9529 28.9978 18.0636 29.4664 18.2198C28.8764 22.0202 26.6898 25.3 23.6182 27.3651Z"
											fill="white"
										></path>
									</svg>
								</a>
							</span>
						</div>
						<div className="add1 address-cont">
							<div className="text-left address-box">
								<h3>EAST COAST</h3>
								<h2 className="text-left">Research Triangle Park, NC</h2>
								<p>
									800, Park Offices Drive, Suite 406,
									<br />
									Research Triangle Park, <br />
									NC 27709 US
								</p>
							</div>
						</div>
						<div className="add2 address-cont">
							<div className="text-left address-box">
								<h3>WEST COAST</h3>
								<h2 className="text-left">SAN JOSE, CA</h2>
								<p>
									75 E Santa Clara Street San Jose,
									<br />
									CA 95113 US
								</p>
							</div>
						</div>
						<div className="add3 address-cont">
							<div className="text-left address-box">
								<h3>INDIA</h3>
								<h2 className="text-left">MOHALI, PB</h2>
								<p>
									I-63, Sector 83, Alpha IT City, Mohali Punjab 140306, India
								</p>
							</div>
						</div>
					</div>
					<div className="footer-wrapper row">
						<div className="col-sm-12 col-md-12 text-left">
							<ul className="footer-links">
								<li>
									<a href="https://intelligaia.com/">Home</a>
								</li>
								<li className="footer-sep">|</li>
								<li>
									<a href="https://intelligaia.com/the-team">About Us</a>
								</li>
								<li className="footer-sep">|</li>
								<li>
									<a href="https://intelligaia.com/work">Work</a>
								</li>
								<li className="footer-sep">|</li>
								<li>
									<a href="https://intelligaia.com/services">Services</a>
								</li>
								<li className="footer-sep">|</li>
								<li>
									<a href="https://intelligaia.com/workshops">Design Sprint</a>
								</li>
								<li className="footer-sep">|</li>
								<li>
									<a href="https://intelligaia.com/blog">
										Thoughts/Methods/Blog
									</a>
								</li>
								<li className="footer-sep">|</li>
								<li>
									<a href="https://intelligaia.com/careers">Careers</a>
								</li>
								<li className="footer-sep">|</li>
								<li>
									<a href="https://intelligaia.com/contact">Contact Us</a>
								</li>
								<li className="footer-sep">|</li>
								<li>
									<a href="https://intelligaia.com/privacy-policy">
										Privacy Policy
									</a>
								</li>
								<li className="footer-sep">|</li>
								<li>
									<a href="https://intelligaia.com/terms-of-use">
										Terms of use
									</a>
								</li>
								<li className="footer-sep">|</li>
								<li>
									<a href="https://intelligaia.com/sitemap">Sitemap</a>
								</li>
								<li className="footer-sep">|</li>
								<li>
									<a href="https://intelligaia.com/how-we-can-help">
										How we can Help/FAQs
									</a>
								</li>
							</ul>
						</div>
						<div className="col-sm-12">
							<div className="row">
								<div className="col-sm-6 text-left">
									<p className="copyright-text">
										© 2021 Intelligaia. All Rights Reserved.
									</p>
								</div>
								<div className="col-sm-6 text-right">
									<img src="/year21.svg" className="" alt="21 Years" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
	
  );
};

export default template;
