import React, { Component } from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import EditRes from "./editbutton";
import PrintPdf from "./printpdf";
var pageurl = window.location.href;
var pageID = pageurl.split("thankyou/")[1];
let fileslist,
	files = [];
export default class Donwloadresponses extends React.Component {
	state = {
		persons: [],
		feed1: [],
		feed2: [],
		feed3: [],
		feed4: [],
		oldfiles: [],
		choice: "",
		fileuploaded: "",
	};

	componentDidMount() {
		axios
			.post(`https://sendgrid.intelligaia.com/email/getUserFeedback`, {
				feed_link: pageID,
			})
			.then((res) => {
				const persons = res.data.data[0];
				const feed1 = res.data.data[0].feedback[0];
				const feed2 = res.data.data[0].feedback[1];
				const feed3 = res.data.data[0].feedback[2];
				const feed4 = res.data.data[0].feedback[3];
				const oldfiles = res.data.data[0].files;
				const choice = res.data.data[0].choice;
				this.setState({
					persons,
					feed1,
					feed2,
					feed3,
					feed4,
					oldfiles,
					choice,
				});
			});
	}

	deleteFile = (filename, index) => {
		let deleteThisFile = filename;
		var r = window.confirm("Please confirm you want to delete " + filename);
		if (r == true) {
			axios
				.post("https://sendgrid.intelligaia.com/email/delete_file", {
					file_name: deleteThisFile,
					feed_link: pageID,
				})
				.then((res) => {
					files = this.state.oldfiles;
					files.splice(index, 1);
					fileslist = [...files];
					this.setState({ oldfiles: files });
				});
		} else {
			return false;
		}
	};
	Filessec() {
		files = this.state.oldfiles;
		fileslist = [...files];
		return (
			<>
				{fileslist.map((file, index) => {
					var filename = file.file_name;
					if (
						filename.endsWith("png") ||
						filename.endsWith("jpg") ||
						filename.endsWith("jpeg") ||
						filename.endsWith("svg")
					) {
						return (
							<li key={index}>
								<img src={file.file_link} className="img-fluid" />
								<button
									onClick={() => this.deleteFile(file.file_name, index)}
									title="Delete File"
								>
									&#10005;
								</button>
							</li>
						);
					} else if (
						filename.endsWith("webm") ||
						filename.endsWith("mp4") ||
						filename.endsWith("mkv") ||
						filename.endsWith("wmv") ||
						filename.endsWith("ogv") ||
						filename.endsWith("ogg")
					) {
						return (
							<li key={index}>
								<a href={file.file_link} target="_blank">
									<img src="/mp4.svg"></img>
								</a>
								<button
									onClick={() => this.deleteFile(file.file_name, index)}
									title="Delete File"
								>
									&#10005;
								</button>
							</li>
						);
					} else if (
						filename.endsWith("doc") ||
						filename.endsWith("docx") ||
						filename.endsWith("txt")
					) {
						return (
							<li key={index}>
								<a href={file.file_link} target="_blank">
									<img src="/doc.svg"></img>
								</a>
								<button
									onClick={() => this.deleteFile(file.file_name, index)}
									title="Delete File"
								>
									&#10005;
								</button>
							</li>
						);
					} else if (filename.endsWith("pdf")) {
						return (
							<li key={index}>
								<a href={file.file_link} target="_blank">
									<img src="/pdf.svg"></img>
								</a>
								<button
									onClick={() => this.deleteFile(file.file_name, index)}
									title="Delete File"
								>
									&#10005;
								</button>
							</li>
						);
					} else if (
						filename.endsWith("rar") ||
						filename.endsWith("tar") ||
						filename.endsWith("zip")
					) {
						return (
							<li key={index}>
								<a href={file.file_link} target="_blank">
									<img src="/zip.svg"></img>
								</a>
								<button
									onClick={() => this.deleteFile(file.file_name, index)}
									title="Delete File"
								>
									&#10005;
								</button>
							</li>
						);
					}
				})}
			</>
		);
	}
	render() {
		return (
			<div className="mainsec mb-5 pb-5">
				<div className="App">
					<EditRes></EditRes>
					<PrintPdf></PrintPdf>
				</div>
				<div className="Heading-sec">
					<div className="container">
						<div className="row">
							<div className="col-sm-7">
								<h1>Hi {this.state.persons.name},</h1>
								<p>
									Thank you for taking the time to complete the worksheet. We
									appreciate your interest in our{" "}
									<strong>{this.state.choice}</strong> program and will get back
									to you within one business day.{" "}
								</p>
							</div>
						</div>
					</div>
				</div>
				<div className="container">
					<div className="worksheet-multistep" id="step_1">
						<div className="purpose-sec mt-5">
							<h3>Purpose</h3>
							<ul className="ml-3 px-0">
								<li className="mb-3">
									Collect some background information and get an idea about what
									the team will be thinking about and doing in the workshop.
								</li>
								<li className="mb-3">
									Having a small catch up chat is an amazing way to get a great
									headstart and getting an understanding about the topic.
								</li>
							</ul>
						</div>
						<div className="steps-sec">
							<h4 className="text-success mt-5">Steps</h4>
							<div className="mb-3">
								<label
									htmlFor="exampleFormControlTextarea1"
									className="form-label"
								>
									Pre Sprint Conversation with Product Managers/Product Owners
								</label>
								<h5>{this.state.feed1.step}</h5>
							</div>
						</div>
						<div className="ques-sec">
							<h4 className="text-success mt-5">Questions</h4>
							<div className="mb-3">
								<label
									htmlFor="exampleFormControlTextarea1"
									className="form-label"
								>
									What are you trying to achieve?
								</label>
								<h5>{this.state.feed1.question}</h5>
							</div>
						</div>
						<div className="Outcome-sec">
							<h4 className="text-success mt-5">Outcome</h4>
							<div className="mb-3">
								<label
									htmlFor="exampleFormControlTextarea1"
									className="form-label"
								>
									Helps formulate How Might We (HMW’s) questions
								</label>
								<h5>{this.state.feed1.outcome}</h5>
							</div>
						</div>
					</div>
					<div className="worksheet-multistep" id="step_2">
						<div className="purpose-sec mt-5">
							<h3>Purpose</h3>
							<ul className="ml-3 px-0">
								<li className="mb-3">
									Framing our own version of what we think a Long Term Goal
									should be and eventually agreeing on the main goal for the
									sprint.
								</li>
							</ul>
						</div>
						<div className="steps-sec">
							<h4 className="text-success mt-5">Steps</h4>
							<div className="mb-3">
								<label
									htmlFor="exampleFormControlTextarea1"
									className="form-label"
								>
									Talk about Long Term Goal
								</label>
								<h5>{this.state.feed2.step}</h5>
							</div>
						</div>
						<div className="ques-sec">
							<h4 className="text-success mt-5">Questions</h4>
							<div className="mb-3">
								<label
									htmlFor="exampleFormControlTextarea1"
									className="form-label"
								>
									What are the top 3 questions you would like to have answered
									in the Sprint?
									<br />
									Where do we want to be in six months, a year, or even five
									years from now?
								</label>
								<h5>{this.state.feed2.question}</h5>
							</div>
						</div>
						<div className="Outcome-sec">
							<h4 className="text-success mt-5">Outcome</h4>
							<div className="mb-3">
								<label
									htmlFor="exampleFormControlTextarea1"
									className="form-label"
								>
									Identify and Define Business Objectives and Expected
									Experience Outcomes
								</label>
								<h5>{this.state.feed2.outcome}</h5>
							</div>
						</div>
					</div>
					<div className="worksheet-multistep" id="step_3">
						<div className="purpose-sec mt-5">
							<h3>Purpose</h3>
							<ul className="ml-3 px-0">
								<li className="mb-3">
									Think about what are the biggest challenges in trying to reach
									the long time goal and maybe even turn them into solution
									oriented questions.
								</li>
							</ul>
						</div>
						<div className="steps-sec">
							<h4 className="text-success mt-5">Steps</h4>
							<div className="mb-3">
								<label
									htmlFor="exampleFormControlTextarea1"
									className="form-label"
								>
									List down the main challenges and situations
								</label>
								<h5>{this.state.feed3.step}</h5>
							</div>
						</div>
						<div className="ques-sec">
							<h4 className="text-success mt-5">Questions</h4>
							<div className="mb-3">
								<label
									htmlFor="exampleFormControlTextarea1"
									className="form-label"
								>
									What is the product/ service/business? What’s the challenge?
									<br />
									What would you like to know from the end user?
								</label>
								<h5>{this.state.feed3.question}</h5>
							</div>
						</div>
						<div className="Outcome-sec">
							<h4 className="text-success mt-5">Outcome</h4>
							<div className="mb-3">
								<label
									htmlFor="exampleFormControlTextarea1"
									className="form-label"
								>
									Helps formulate Can We questions
								</label>
								<h5>{this.state.feed3.outcome}</h5>
							</div>
						</div>
					</div>
					<div className="worksheet-multistep" id="step_4">
						<div className="purpose-sec mt-5">
							<h3>Purpose</h3>
							<ul className="ml-3 px-0">
								<li className="mb-3">
									It’s crucial to think about the customers and the target
									market you may have or already know. This will help choose a
									behavior that we want to influence through the solution.
								</li>
							</ul>
						</div>
						<div className="steps-sec">
							<h4 className="text-success mt-5">Steps</h4>
							<div className="mb-3">
								<label
									htmlFor="exampleFormControlTextarea1"
									className="form-label"
								>
									Ask general questions about customers and the target markets
								</label>
								<h5>{this.state.feed4.step}</h5>
							</div>
						</div>
						<div className="ques-sec">
							<h4 className="text-success mt-5">Questions</h4>
							<div className="mb-3">
								<label
									htmlFor="exampleFormControlTextarea1"
									className="form-label"
								>
									Who are the existing and target customers for the sprint?
									What’s the gap between them? What does the typical user
									journey look like? What’s working well, what’s not?
								</label>
								<h5>{this.state.feed4.question}</h5>
							</div>
						</div>
						<div className="Outcome-sec">
							<h4 className="text-success mt-5">Outcome</h4>
							<div className="mb-3">
								<label
									htmlFor="exampleFormControlTextarea1"
									className="form-label"
								>
									Leads to Target profile and to initiate a first draft of the
									Map
								</label>
								<h5>{this.state.feed4.outcome}</h5>
							</div>
						</div>
					</div>
					<div className="files-listing">
						<ul>{this.Filessec()}</ul>
					</div>
				</div>
			</div>
		);
	}
}
