import React, { Component } from "react";
var pageurl = window.location.href;
var pageID = pageurl.split("thankyou/")[1];

const EditRes = (props) => {
    let history = '/worksheet/'+pageID;
    return(
        <a  className="btn btn-primary ml-3" href={history}>Edit Response</a>
    )
}

export default EditRes;